<template>
    <div>
        <b-button-toolbar>
            <b-button-group class="mx-1">
                <b-dropdown text="Users" v-if="isAllowed">
                    <b-dropdown-item @click="$router.push('/admin/user/create')">Create New Users</b-dropdown-item>
                </b-dropdown>
            </b-button-group>
            <b-button-group class="mx-1">
                <b-dropdown text="General">
                    <b-dropdown-item @click="$router.push('/admin/editor/templates')">Edit Schedule Templates</b-dropdown-item>
                    <b-dropdown-item @click="$router.push('/admin/editor/carts')">Edit Cartridges</b-dropdown-item>
                    <b-dropdown-divider v-if="isAllowed"></b-dropdown-divider>
                    <b-dropdown-item v-if="isSuperAllowed" @click="$router.push('/admin/editor/companies')">Edit Company List</b-dropdown-item>
                    <b-dropdown-divider v-if="isSuperAllowed"></b-dropdown-divider>
                    <b-dropdown-item v-if="isSuperAllowed" @click="$router.push('/admin/fw/manager')">FW Manager</b-dropdown-item>
                </b-dropdown>
            </b-button-group>
        </b-button-toolbar>
    </div>
</template>

<script>
import roles from '@/roles';
export default {
    name: "AdminMenu",
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {
        isAllowed() {
            if (this.$store.getters.role >= roles.roles.accountManager)
                return true;
            else
                return false;
        },
        isSuperAllowed() {
            if (this.$store.getters.role >= roles.roles.superUser)
                return true;
            else
                return false;
        }
    }
}
</script>

<style scoped>

</style>
